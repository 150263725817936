import React, { useState } from "react";
import { Home, Users, Folder, Menu, Send, User } from "react-feather";
import Modal from "../modal/modal";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./navbar.css";

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);

  const navItems = [
    { icon: "home", component: <Home />, label: "Accueil", lien: "/" },
    { icon: "user", component: <User />, label: "Profile", lien: "/about" },
    { icon: "users", component: <Users />, label: "Avis", lien: "/avis" },
    {
      icon: "folder",
      component: <Folder />,
      label: "Projects",
      lien: "/projets",
    },
    {
      icon: "send",
      component: <Send />,
      label: "Contacter",
      onClick: () => setShowModal(true),
    },
  ];
  const renderNavLinks = () => {
    return navItems.map((item) => (
      <li className="navbar__item" key={item.label}>
        
        <Link to={item.lien} className="navbar__link text-white md:text-black" onClick={item.onClick}>
          <i data-feather={item.icon}></i>
            <div className="flex flex-col justify-center items-center">
              {item.component}
              <p className='md:hidden'>{item.label}</p>
            </div>
          <span className="thx">{item.label}</span>
        </Link>
      </li>
    ));
  };

  return (
    <>
      <div className="drawer drawer-end ">
        <label htmlFor="my-drawer" className="hidden">
          Libellé du menu :
        </label>
        <input
          data-feather={<Menu />}
          id="my-drawer"
          type="checkbox"
          className="drawer-toggle"
        ></input>
        <div className="drawer-content">
          <label htmlFor="my-drawer" className="btn btn-ghost drawer-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-5 h-5 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </label>
        </div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-2 w-28 h-full bg-base-200 text-base-content">
            {renderNavLinks()}
          </ul>
        </div>
      </div>
      <nav className="navbare">
        <h2 className="text-center text-black">Menu</h2>
        <ul className="navbar__menu">{renderNavLinks()}</ul>
        {showModal && (
          <Modal showModal={showModal} onClose={() => setShowModal(false)} />
        )}
      </nav>
    </>
  );
};

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
