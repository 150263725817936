import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./modal.css";
import { motion } from "framer-motion";
import { animation,item, item2 } from "../../components/animation";


const Modal = ({ showModal, onClose }) => {
  console.log(showModal);
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  return (
    <dialog
      className="modal modal-open modal-bottom sm:modal-middle opacity-100 visible "
    >
      <motion.form  method="dialog" className="modal-box shadow-none overflow-hidden " variants={animation} initial="hidden" animate="show">
        <h3 className="font-bold text-lg text-center">Me contacter</h3>

        {/* formulaire de parametres */} 
        <div  className="form-control">
          <motion.label variants={item} className="label">
            <span className="label-text">Nom</span>
          </motion.label>
          <motion.input
            variants={item2}
            type="text"
            className="input input-bordered"
          />
        </div>
        <div  className="form-control">
          <motion.label variants={item} className="label">
            <span className="label-text">Prenom</span>
          </motion.label>
          <motion.input
            variants={item2}
            type="text"
            className="input input-bordered"
          />
        </div>
        <div  className="form-control">
          <motion.label variants={item} className="label">
            <span className="label-text">Email</span>
          </motion.label>
          <motion.input
            variants={item2}
            type="text"
            className="input input-bordered"
          />
        </div>

        <div  className="form-control">
          <motion.label variants={item} className="label">
            <span className="label-text">Message</span>
          </motion.label>
          <motion.textarea
            variants={item2}
            className="textarea textarea-bordered"
          />
        </div>

        <div className="modal-action">
          <button className="btn btn-secondary" >Envoyer</button>
          <button className="btn" onClick={onClose}>Close</button>
        </div>
      </motion.form>
    </dialog>
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
