import React from "react";
import PropTypes from "prop-types";
import "./commentscard.css";

const Commentscard = (props) => {
  return (
    <div className="relative grid grid-cols-1 gap-4 p-4 mb-8 border rounded-lg bg-white shadow-lg m-auto max-w-md">
      <div className="relative flex gap-4 ">
        <div className="relative ring ring-secondary ring-offset-base-300 ring-offset-1 rounded-lg">
          <img
            src={props.props.img}
            className="rounded-lg h-20 w-20 object-cover"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-col justify-between">
            <p className="relative text-xl whitespace-nowrap truncate overflow-hidden">
              {props.props.nom}
            </p>
            <p className="relative text-sm truncate overflow-hidden">
              {props.props.metier}
            </p>
          </div>
          <p className="text-gray-400 text-sm text-justify">
            {props.props.date}
          </p>
        </div>
      </div>
      <p className="-mt-4 text-gray-500 mt-3">{props.props.comment}</p>
    </div>
  );
};

Commentscard.propTypes = {};

Commentscard.defaultProps = {};

export default Commentscard;
