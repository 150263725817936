import React from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Home from "./pages/home/home";
import About from "./pages/about/about";
import Credential from "./pages/credential/credential";
import Projets from "./pages/projets/projets";

const Routing = () => {
  const location = useLocation();

  return (
    <div className="bg-base-100">
      <Navbar />

      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/avis" element={<Credential />} />
          <Route path="/projets" element={<Projets />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default Routing;
