import React from "react";
import PropTypes from "prop-types";
import "./credential.css";
import Transition from "../../components/transition";
import { motion } from "framer-motion";
import { animation, item, item2 } from "../../components/animation";
import Commentscard from "../../components/commentscard/commentscard";

const Credential = () => {
  const avis = [
    {
      img: "https://media.licdn.com/dms/image/D4E03AQEWQtvYC0wl7A/profile-displayphoto-shrink_800_800/0/1682508702864?e=2147483647&v=beta&t=t9UxEfgp8KaYVWyMnqCPMlUTq2lDrAevpY2oanhCI0w",
      nom: "Cynthia Zeitoun",
      metier: "Consultante Recrutement & formatrice ",
      date: "Le 3 avril 2023",
      comment:
        "J'ai eu l'occasion de rencontrer Andréas durant sa formation chez OpenClassrooms. Andréas est quelqu'un de réfléchi et d'attentif. Toujours à l'écoute, il met tout en œuvre pour être opérationnel. Il n'hésite pas à s'investir dans des projets de dev pour améliorer et optimiser toutes ces compétences. Agréable et sympathique, Andreas sera un atout dans une équipe projet !",
    },
    {
      img: "https://firebasestorage.googleapis.com/v0/b/alain-18a2c.appspot.com/o/310963856_5495053100532370_4343353308549540811_n.jpg?alt=media&token=3b4515fa-1faa-48d5-b5a4-84c9e3d67731",
      nom: "Alain Lamy",
      date: "Le 24 juillet 2023",
      metier: "Artiste Peintre | Intervenant intergénérationnel",
      comment: "J'ai confié la réalisation de mon nouveau site à Andréas Arnolfo. J'en suis totalement satisfait. Il s'est rendu très disponible, à l'écoute et très réactif. Il a été force de proposition pour que le site soit très attractif et me corresponde. Je ne peux que vous conseiller de lui faire confiance. Il saura à coup sûr s'adapter à votre demande. Un grand merci à Andréas.",
    },
  ];

  //reverse the array
  avis.reverse();
  return (
    <div className="relative min-h-screen max-h-screen max-w-screen flex flex-col justify-center items-center overflow-hidden box-border snap-none">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="100%"
        id="blobSvg"
        className="absolute top-0 left-0 z-0 select-none w-full h-full"

      >
        {" "}
        <defs>
          {" "}
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            {" "}
            <stop
              offset="0%"
              
            ></stop>{" "}
            <stop offset="100%" ></stop>{" "}
          </linearGradient>{" "}
        </defs>{" "}
        <path id="blob" fill="#50717b">
          {" "}
          <animate
            attributeName="d"
            dur="10000ms"
            repeatCount="indefinite"
            values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"
          ></animate>{" "}
        </path>{" "}
      </svg>
      <motion.div
        className="carousel w-full"
        variants={animation}
        initial="hidden"
        animate="show"
      >
        {avis.map((avis, index) => (
          <motion.div
            key={index}
            variants={item}
            className="carousel-item w-full "
            id={`item${index}`}
          >
            <Commentscard props={avis} key={index} />
          </motion.div>
        ))}
      </motion.div>
      <motion.div
        variants={animation}
        className="flex justify-center w-full py-2 gap-2"
      >
        {avis.map((avis, index) => (
          <motion.a
            variants={item2}
            href={`#item${index}`}
            key={index}
            className="btn btn-xs z-10"
          >
            {index + 1}
          </motion.a>
        ))}
      </motion.div>
    </div>
  );
};

Credential.propTypes = {};

Credential.defaultProps = {};

export default Transition(Credential);
