import React from "react";
import PropTypes from "prop-types";
import "./home.css";
import Transition from "../../components/transition";
import { motion } from "framer-motion";
import { animation, item } from "../../components/animation";

const Home = () => (
  <div className="hero min-h-screen bg-base-100">
    <motion.div
      className="hero-content text-center"
      variants={animation}
      initial="hidden"
      animate="show"
    >
      <div className="overflow-hidden">
        <div className="overflow-hidden">
          <motion.img
            variants={item}
            src="https://media.discordapp.net/attachments/1123629311434424462/1159890769419714620/Main_Logo-removebg-preview.png?ex=6532ab75&is=65203675&hm=38f75bb3e08a4e0972fd71b06543dc10a3f1445153c42e4096329079c172b102&=&width=625&height=625"
            className="mr-auto ml-auto overflow-hidden "
            style={{width: "40vw", height: "auto"}}
            alt="Logo"
          />
        </div>
        <div className="overflow-hidden">
          <motion.h1
            variants={item}
            className="font-bold myFont overflow-hidden"
          >
            Développeur web
          </motion.h1>
        </div>
        <div className="overflow-hidden">
          <motion.p
            variants={item}
            className="py-6 max-w-lg overflow-hidden m-auto"
          >
            Création de site web sur mesure
          </motion.p>
        </div>
      </div>
    </motion.div>
  </div>
);

Home.propTypes = {};

Home.defaultProps = {};

export default Transition(Home);
