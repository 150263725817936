import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./projets.css";
import Transition from "../../components/transition";
import { AnimatePresence, delay, motion } from "framer-motion";
import { animation, item, item2, item3 } from "../../components/animation";

const Projets = () => {
  const projets = [
    {
      title: "Booki",
      description: "Transformer une maquette en site web avec HTML & CSS",
      image:
        "https://camo.githubusercontent.com/090245d84c3bff798ba5844825ea363cd59560964adc741585b699ca3d8d7c03/68747470733a2f2f757365722e6f632d7374617469632e636f6d2f75706c6f61642f323032312f30392f32392f31363332393238303334333035315f4465736b746f702532302d253230312e706e67",
      url: "https://github.com/andreasArnolfo-S/P2_Andreas_code",
      video: "https://www.youtube.com/embed/b133TlvPFuE",
      image2: 'https://cdn.discordapp.com/attachments/1123629311434424462/1140713279837184130/image.png',
      id: 1,
    },
    {
      title: "Oh myfood!",
      description: "Dynamiser une page web avec des animations CSS",
      image:
        "https://camo.githubusercontent.com/ddd91e114474e932046515ff52197b87c834e5232f93f0fe2ba0a1ab94ec131e/68747470733a2f2f757365722e6f632d7374617469632e636f6d2f75706c6f61642f323032302f30382f32342f31353938323630353930383431385f4d61717565747465732532304f686d79666f6f642e6a7067",
      url: "https://github.com/andreasArnolfo-S/AndreasArnolfo_3_15012022",
      video: "https://www.youtube.com/embed/nk7xdKBeFFY",
      image2: 'https://cdn.discordapp.com/attachments/1123629311434424462/1144944284248846436/image.png',
      id: 2,
    },
    {
      title: "GameOn",
      description: "Créer une landing page avec Javascript",
      image:
        "https://user.oc-static.com/upload/2021/12/15/16395717662959_HomePage.png",
      url: "https://github.com/andreasArnolfo-S/GameOn-website-FR",
      image2: 'https://cdn.discordapp.com/attachments/1123629311434424462/1140721741266681906/image.png',
      id: 3,
    },
    {
      title: "FishEye",
      description: "Créer un site web de photographes",
      image:
        "https://user.oc-static.com/upload/2022/10/14/16657380159236_Index%20%281%29.png",
      url: "https://github.com/andreasArnolfo-S/Fisheye-P6",
      image2: 'https://cdn.discordapp.com/attachments/1123629311434424462/1140721944174538752/image.png',
      id: 4,
    },
    {
      title: "Les petits plats",
      description: "Créer un site de recettes de cuisine",
      image:
        "https://logic-fabric.github.io/logic-fabric/img/projects/les-petits-plats.jpg",
      url: "https://github.com/andreasArnolfo-S/P7-Les-Petits-Plats",
      image2: 'https://cdn.discordapp.com/attachments/1123629311434424462/1140722156699918467/image.png',
      id: 5,
    },
    {
      title: "Billed",
      description: "Débugger et testez un SaaS RH",
      image:
        "https://user.oc-static.com/upload/2020/08/14/15973967670682_image1.png",
      url: "https://github.com/andreasArnolfo-S/p9-Front",
      image2: 'https://user.oc-static.com/upload/2020/08/14/15973967670682_image1.png',
      id: 6,
    },
    {
      title: "Kasa",
      description: "Développer une application Web avec React et React Router",
      image:
        "https://user.oc-static.com/upload/2022/06/24/16560899769906_FR_811_P8_Banner-Kasa%20%281%29.png",
      url: "https://github.com/andreasArnolfo-S/p11-Kasa",
      image2: 'https://cdn.discordapp.com/attachments/1123629311434424462/1140722432404107264/image.png',
      id: 8,
    },
    {
      title: "SportSee",
      description: "Développer un tableau de bord d'analytics avec React",
      image:
        "https://cdn.discordapp.com/attachments/978413517780697141/1068922757372465225/image.png",
      url: "https://github.com/andreasArnolfo-S/p12-SportSee",
      image2: 'https://cdn.discordapp.com/attachments/978413517780697141/1068922757372465225/image.png',
      id: 9,
    },

  ];
  const [selectedId, setSelectedId] = useState(null);
  const [myprojets, setMyprojets] = useState([]);

  projets.reverse();

  const onKeyDown = (event) => {
    if (event.key === "Escape") {
      setSelectedId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  })

  return (
    <motion.div
      variants={animation}
      initial="hidden"
      animate="show"
      className="flex flex-wrap justify-center gap-20 mt-5"
    >
      {projets.map((p, index) => (
        <motion.div
          key={index}
          variants={item3}
          layoutId={p.id}
          onClick={() => [setSelectedId(p.id), setMyprojets(p)]}
          className="card w-28 h-28 md:w-96 md:h-96 bg-base-300 shadow-xl cursor-pointer p-4 overflow-hidden rounded-lg"
        >
          <motion.figure className="max-h-52 rounded-lg">
            <motion.img
              src={p.image}
              alt="Shoes"
              className="hidden md:block w-full object-cover rounded-lg h-52"
            />
          </motion.figure>
          <motion.div className="card-body p-0 md:card-body">
            <motion.h2 className="card-title m-auto text-center">{p.title}</motion.h2>
            <motion.p className="hidden md:block text-center">{p.description}</motion.p>
          </motion.div>
        </motion.div>
      ))}
      <AnimatePresence>
        {selectedId && (
          <motion.div
            layoutId={selectedId}
            className="absolute top-0 left-0 w-full h-full flex justify-center items-center"
          >
            <motion.div className="card mycard bg-base-300 shadow-xl w-5/6 m-auto relative">
              <motion.h1 className="text-center text-2xl font-bold my-5">
                {myprojets.title}
              </motion.h1>
              <motion.img 
              src={myprojets.image2} className="w-full object-cover rounded-lg shadow-sm" />
              <motion.button
                className="btn btn-sm mt-20 m-auto rounded-full absolute right-4 -top-16 bg-red-500"
                onClick={() => setSelectedId(null)}
              >
                X
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

Projets.propTypes = {};

Projets.defaultProps = {};

export default Transition(Projets);
