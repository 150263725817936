import React from 'react';
import PropTypes from 'prop-types';
import './about.css';
import Transition from "../../components/transition";

const About = () => (
<section className="pt-16 bg-blueGray-50">
<div className="w-full lg:w-6/12 px-4 mx-auto">
  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16 test">
    <div className="px-6">
      <div className="flex flex-wrap justify-center">
        <div className="w-full px-4 flex justify-center">
          <div className="relative rounded-full h-60 w-60 mt-5 ring ring-offset-base-300 ring-offset-2">
            <img src="https://media.discordapp.net/attachments/1123629311434424462/1159823114180497439/IMG_1047.webp?ex=65326c72&is=651ff772&hm=3f421d66ca1a861230edee515310c576acc020efe55abf2b1af3e7e806858c46&=&width=550&height=816" className="shadow-xl align-middle border-none object-cover object-center h-full w-full rounded-full" alt='Photo de andreas arnolfo'/>
          </div>
        </div>
        <div className="w-full px-4 text-center mt-5">
          <div className="flex justify-center py-4 lg:pt-4 pt-8">
            <div className="mr-4 p-3 text-center">
              <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                1
              </span>
              <span className="text-sm text-blueGray-400">Clients</span>
            </div>
            <div className="mr-4 p-3 text-center">
              <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                14
              </span>
              <span className="text-sm text-blueGray-400">Projets</span>
            </div>
            <div className="lg:mr-4 p-3 text-center">
              <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                2
              </span>
              <span className="text-sm text-blueGray-400">Avis</span>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center ">
        <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 myFont2">
          Andreas Arnolfo
        </h3>
        <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
          <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
          Montpellier, France
        </div>
        <div className="mb-2 text-blueGray-600 mt-10">
          <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
          Developpeur web et mobile
        </div>
        <div className="mb-2 text-blueGray-600">
          <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
          Master en developpement d'applications web avec React
        </div>
        <div className="mb-2 text-blueGray-600">
          <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
          Numéro Siret: 95379719800017
        </div>
      </div>
      <div className="mt-10 py-10 border-t border-blueGray-200 text-center pb-52">
        <div className="flex flex-wrap justify-center">
          <div className="w-full lg:w-9/12 px-4">
            <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
              Je suis un développeur front-end passionné avec une expérience dans la création de sites web et d'applications dynamiques en utilisant HTML, CSS, Javascript et React.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</section>
);

About.propTypes = {};

About.defaultProps = {};

export default Transition(About);
