export const animation = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChilder: 0.7,
      staggerChildren: 0.2,
    },
  },
};

export const item = {
  hidden: { y: '100%'},
  show: {
    y: '0%',
    transition: {
      duration: 0.6,
    }
  },
};

export const item2 = {
  hidden: { x: '100%'},
  show: {
    x: '0%',
    transition: {
      duration: 0.3,
    }
  }
};

export const item3 = {
  hidden: { opacity: 0},
  show: {
    opacity: 1,
    transition: {
      duration: 0.6,

    }
  }
};