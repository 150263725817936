import React from "react";
import { motion } from "framer-motion";

export default function Transition(OgComponent) {
  return () => (
    <>
      <OgComponent />
      <motion.div
        className="slide-in flex justify-center items-center"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 1 }}
        transition={{ duration: 0.7, ease: [0.22, 1, 0.36, 1] }}
      >

      </motion.div>
      <motion.div
        className="slide-out flex justify-center items-center"
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      >

      </motion.div>
    </>
  );
}
